import axios from "axios";
import qs from "qs";
import { API_BASE_URL } from "@/config/config.local";

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    // 设置全局的baseURL
    axios.defaults.baseURL = API_BASE_URL;

    // 全局将对象数据转换为queryString数据, 这样提交到后台的数据是表单, 而不是json
    // axios 会根据数据自动设置 header 的 Content-type, 无需手动设置此header
    // 取消全局设置, 因为会和文件上传冲突, 在调用post时直接使用 qs.stringify() 即可
    // axios.defaults.transformRequest = [
    //   (data) => {
    //     return qs.stringify(data);
    //   },
    // ];

    // 允许携带 cookie, 以实现 session 功能
    axios.defaults.withCredentials = true;
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    return axios.post(resource, qs.stringify(params));
  },

  /**
   * Set the POST HTTP request for upload file
   * @param resource
   * @param formData
   * @returns {*}
   */
  upload(resource, formData) {
    return axios.post(resource, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
};

ApiService.init();

export default ApiService;
