import "bootstrap/dist/css/bootstrap.min.css";
import router from "./router";
import store from "./store";
import { createApp } from "vue";
import { Button, Cell, CellGroup, Empty, Icon, Loading, NavBar } from "vant";
import App from "./App.vue";
import "@/assets/css/app.css";

createApp(App)
  .use(store)
  .use(router)
  .use(Button)
  .use(Cell)
  .use(CellGroup)
  .use(Empty)
  .use(Icon)
  .use(Loading)
  .use(NavBar)
  .mount("#app");
