import ApiService from "@/api/api.service";

// action types
export const LOGIN = "login";
export const LOGOUT = "logout";
export const VERIFY_AUTH = "verifyAuth";
export const VERIFY_AGENT = "verifyAgent";

// mutation types
export const SET_AUTH = "setUser";
export const SET_AGENT = "setAgent";
export const PURGE_AUTH = "logOut";
export const PURGE_AGENT = "resetAgentInfo";
export const FORCE_LOGIN = "forceSmsLogin";

// 用户状态数据
const state = {
  isAuthenticated: false,
  forceSmsLogin: JSON.parse(window.localStorage.getItem("forceSmsLogin")),
  currentUser: JSON.parse(window.localStorage.getItem("currentUser")),
  agentInfo: JSON.parse(window.localStorage.getItem("agentInfo")),
};

const getters = {
  currentUser(state) {
    return state.currentUser;
  },
  agentInfo(state) {
    return state.agentInfo;
  },
  forceSmsLogin(state) {
    return state.forceSmsLogin;
  },
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("/common/auth/login", credentials)
        .then(({ data }) => {
          if (data && data.result) {
            context.commit(SET_AUTH, data.result);
            resolve(data);
          } else {
            context.commit(PURGE_AGENT);
            reject("服务器返回数据异常");
          }
        })
        .catch(({ response }) => {
          reject(response.data.msg);
        });
    });
  },
  [LOGOUT](context) {
    return new Promise((resolve, reject) => {
      ApiService.post("/common/auth/logout")
        .then(({ data }) => {
          context.commit(PURGE_AUTH);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.msg);
        });
    });
  },
  [VERIFY_AUTH](context) {
    const currentTime = new Date().format("yyyy-MM-dd hh:mm:ss");
    if (!state.currentUser || state.currentUser.login_expire_at < currentTime) {
      context.commit(PURGE_AUTH);
    }
  },
  [VERIFY_AGENT](context) {
    return new Promise((resolve, reject) => {
      ApiService.post("/common/user/getAgentInfo")
        .then(({ data }) => {
          if (data && data.result) {
            context.commit(SET_AGENT, data.result);
            resolve(data);
          } else {
            reject({ data: { msg: "服务器返回数据异常" } });
          }
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
};

const mutations = {
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.forceSmsLogin = false;
    state.currentUser = user;
    window.localStorage.setItem("forceSmsLogin", JSON.stringify(false));
    window.localStorage.setItem("currentUser", JSON.stringify(user));
  },
  [SET_AGENT](state, agent) {
    state.agentInfo = agent;
    window.localStorage.setItem("agentInfo", JSON.stringify(agent));
  },
  [PURGE_AGENT](state) {
    state.agentInfo = null;
    window.localStorage.removeItem("agentInfo");
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.currentUser = null;
    state.agentInfo = null;
    window.localStorage.removeItem("currentUser");
    window.localStorage.removeItem("agentInfo");
  },
  // 注意: 在登出操作时手动提交此方法, 强制用户需短信登录, 但是在自动退出时不要调用
  [FORCE_LOGIN](state) {
    state.forceSmsLogin = true;
    window.localStorage.setItem("forceSmsLogin", JSON.stringify(true));
  },
};

export default {
  namespace: false,
  state,
  getters,
  actions,
  mutations,
};
