// 是否关注公众号才能充电, 如果开启必须配置 WECHAT_SUBSCRIBE_URL
export const FORCE_SUBSCRIBE = true;

// 后端API基础地址, 末尾不要添加 "/", 使用当前页面地址和协议, 以便支持多域名访问前端
export const API_BASE_URL = `${window.location.protocol}//${window.location.host}/api`;

export const APP_NAME = "多依智造";
export const APP_BANNER = "";
export const APP_BANNER_HEIGHT = 200;
export const WECHAT_APP_ID = "wxdf526e76a12c02e2";
export const WECHAT_SUBSCRIBE_URL =
  "https://mp.weixin.qq.com/s/vF9nkSxoZsU3Ui2Ob4bKRg";

// 扫码充电二维码里的域名白名单列表, 一般是当前前端部署的域名
export const FRONTEND_DOMAIN_WHITELIST = ["ades.cn", "duoyifin.com"];

// 数组, 支持的一次性订阅消息模板ID, 留空则意味着不开启模板消息
export const ONETIME_MSG_IDS = [
  "1nsP6im80vEZjI-bgTV3k6tBoVNwdkMeqAjaA0yMVDc",
  "ZAy-auCdQbvlrSHbjwzofiartvir8j-z8zVsibBci8Y",
];

// 可配置的功能模块
export const MODULES = {
  // 支持工行数币充值
  ICBC_DIGITAL_PAYMENT: true,
};

export default {
  appName: APP_NAME,
  appBanner: APP_BANNER,
  appBannerHeight: APP_BANNER_HEIGHT,
  apiBaseUrl: API_BASE_URL,
  wechatAppId: WECHAT_APP_ID,
  frontendDomainWhitelist: FRONTEND_DOMAIN_WHITELIST,
  onetimeMsgIds: ONETIME_MSG_IDS,
  modules: MODULES,
};
