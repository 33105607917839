import store from "@/store";
import { isObject } from "is-what";
import { createRouter, createWebHistory } from "vue-router";
import { domTitle, setDocumentTitle } from "@/utils/domUtil";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import(/* webpackChunkName: "Home" */ "../views/Home.vue"),
  },
  {
    path: "/login",
    name: "Login",
    meta: { notRequireAuth: true },
    component: () =>
      import(/* webpackChunkName: "Login" */ "../views/Login.vue"),
  },
  {
    path: "/power",
    name: "Power",
    meta: { notRequireAuth: true }, // 在路由级别不校验登录, 在程序内部校验, 因为首次扫码进入时需要记录充电端口参数
    redirect: "/power/recharge",
    component: () =>
      import(/* webpackChunkName: "Power" */ "../views/power/Power.vue"),
    children: [
      {
        path: "recharge",
        name: "PowerRecharge",
        component: () =>
          import(
            /* webpackChunkName: "PowerRecharge" */ "../views/power/Recharge.vue"
          ),
      },
      {
        path: "nearby",
        name: "PowerNearby",
        component: () =>
          import(
            /* webpackChunkName: "PowerNearby" */ "../views/power/Nearby.vue"
          ),
      },
    ],
  },
  {
    path: "/user",
    name: "User",
    redirect: "/user/dashboard",
    component: () =>
      import(/* webpackChunkName: "User" */ "../views/user/User.vue"),
    children: [
      {
        path: "dashboard",
        name: "UserDashboard",
        component: () =>
          import(
            /* webpackChunkName: "UserDashboard" */ "../views/user/Dashboard.vue"
          ),
      },
      {
        path: "profile",
        name: "UserProfile",
        component: () =>
          import(
            /* webpackChunkName: "UserProfile" */ "../views/user/Profile.vue"
          ),
      },
      {
        path: "recharge",
        name: "UserRecharge",
        component: () =>
          import(
            /* webpackChunkName: "UserRecharge" */ "../views/user/Recharge.vue"
          ),
      },
      {
        path: "recharge-log",
        name: "UserRechargeLog",
        component: () =>
          import(
            /* webpackChunkName: "UserRechargeLog" */ "../views/user/RechargeLog.vue"
          ),
      },
      {
        path: "recharge-detail",
        name: "UserRechargeDetail",
        component: () =>
          import(
            /* webpackChunkName: "UserRechargeDetail" */ "../views/user/RechargeDetail.vue"
          ),
      },
      {
        path: "balance-cash",
        name: "BalanceCash",
        component: () =>
          import(
            /* webpackChunkName: "BalanceCash" */ "../views/user/BalanceCash.vue"
          ),
      },
      {
        path: "balance-cash-detail",
        name: "BalanceCashDetail",
        component: () =>
          import(
            /* webpackChunkName: "BalanceCash" */ "../views/user/BalanceCashDetail.vue"
          ),
      },
      {
        path: "balance-gift",
        name: "BalanceGift",
        component: () =>
          import(
            /* webpackChunkName: "BalanceGift" */ "../views/user/BalanceGift.vue"
          ),
      },
      {
        path: "balance-gift-detail",
        name: "BalanceGiftDetail",
        component: () =>
          import(
            /* webpackChunkName: "BalanceGift" */ "../views/user/BalanceGiftDetail.vue"
          ),
      },
      {
        path: "chargingorder",
        name: "UserChargingOrder",
        component: () =>
          import(
            /* webpackChunkName: "UserChargingOrder" */ "../views/user/ChargingOrder.vue"
          ),
      },
      {
        path: "chargingorder-detail",
        name: "UserChargingOrderDetail",
        component: () =>
          import(
            /* webpackChunkName: "UserChargingOrderDetail" */ "../views/user/ChargingOrderDetail.vue"
          ),
      },
    ],
  },
  {
    path: "/general-agent",
    name: "GeneralAgent",
    redirect: "/general-agent/dashboard",
    component: () =>
      import(
        /* webpackChunkName: "GeneralAgent" */ "../views/general-agent/GeneralAgent.vue"
      ),
    children: [
      {
        path: "dashboard",
        name: "GeneralAgentDashboard",
        meta: { requireAgentLevel: 1 },
        component: () =>
          import(
            /* webpackChunkName: "GeneralAgentDashboard" */ "../views/general-agent/Dashboard.vue"
          ),
      },
      {
        path: "agent-list",
        name: "AgentList",
        meta: { requireAgentLevel: 1 },
        component: () =>
          import(
            /* webpackChunkName: "AgentList" */ "../views/general-agent/AgentList.vue"
          ),
      },
      {
        path: "agent-detail",
        name: "AgentDetail",
        meta: { requireAgentLevel: 1 },
        component: () =>
          import(
            /* webpackChunkName: "AgentDetail" */ "../views/general-agent/AgentDetail.vue"
          ),
      },
      {
        path: "agent-form",
        name: "AgentForm",
        meta: { requireAgentLevel: 1 },
        component: () =>
          import(
            /* webpackChunkName: "AgentForm" */ "../views/general-agent/AgentForm.vue"
          ),
      },
    ],
  },
  {
    path: "/agent",
    name: "Agent",
    redirect: "/agent/dashboard",
    component: () =>
      import(/* webpackChunkName: "Agent" */ "../views/agent/Agent.vue"),
    children: [
      {
        path: "dashboard",
        name: "AgentDashboard",
        meta: { requireAgentLevel: 2 },
        component: () =>
          import(
            /* webpackChunkName: "AgentDashboard" */ "../views/agent/Dashboard.vue"
          ),
      },
      {
        path: "chargingorder",
        name: "AgentChargingOrder",
        meta: { requireAgentLevel: 0 },
        component: () =>
          import(
            /* webpackChunkName: "AgentChargingOrder" */ "../views/agent/ChargingOrder.vue"
          ),
      },
      {
        path: "chargingorder-detail",
        name: "AgentChargingOrderDetail",
        meta: { requireAgentLevel: 0 },
        component: () =>
          import(
            /* webpackChunkName: "AgentChargingOrderDetail" */ "../views/agent/ChargingOrderDetail.vue"
          ),
      },
      {
        path: "report",
        name: "AgentReport",
        meta: { requireAgentLevel: 0 },
        component: () =>
          import(
            /* webpackChunkName: "AgentReport" */ "../views/agent/Report.vue"
          ),
      },
      {
        path: "wallet",
        name: "AgentWallet",
        meta: { requireAgentLevel: 0 },
        component: () =>
          import(
            /* webpackChunkName: "AgentWallet" */ "../views/agent/Wallet.vue"
          ),
      },
      {
        path: "profile",
        name: "AgentProfile",
        meta: { requireAgentLevel: 0 },
        component: () =>
          import(
            /* webpackChunkName: "AgentProfile" */ "../views/agent/Profile.vue"
          ),
      },
      {
        path: "profile-update",
        name: "AgentProfileUpdate",
        meta: { requireAgentLevel: 0 },
        component: () =>
          import(
            /* webpackChunkName: "AgentProfileUpdate" */ "../views/agent/ProfileUpdate.vue"
          ),
      },
    ],
  },
  {
    path: "/station",
    name: "Station",
    redirect: "/station/list",
    component: () =>
      import(/* webpackChunkName: "Station" */ "../views/station/Station.vue"),
    children: [
      {
        path: "list",
        name: "StationList",
        meta: { requireAgentLevel: 0 },
        component: () =>
          import(
            /* webpackChunkName: "StationList" */ "../views/station/List.vue"
          ),
      },
      {
        path: "detail",
        name: "StationDetail",
        meta: { requireAgentLevel: 0 },
        component: () =>
          import(
            /* webpackChunkName: "StationDetail" */ "../views/station/Detail.vue"
          ),
      },
      {
        path: "form",
        name: "StationForm",
        meta: { requireAgentLevel: 0 },
        component: () =>
          import(
            /* webpackChunkName: "StationForm" */ "../views/station/Form.vue"
          ),
      },
      {
        path: "fee",
        name: "StationFee",
        meta: { requireAgentLevel: 2 },
        component: () =>
          import(
            /* webpackChunkName: "StationFee" */ "../views/station/Fee.vue"
          ),
      },
      {
        path: "syncfee",
        name: "Syncfee",
        meta: { requireAgentLevel: 2 },
        component: () =>
          import(
            /* webpackChunkName: "Syncfee" */ "../views/station/Syncfee.vue"
          ),
      },
      {
        path: "profit-sharing",
        name: "StationProfitSharing",
        meta: { requireAgentLevel: 1 },
        component: () =>
          import(
            /* webpackChunkName: "StationProfitSharing" */ "../views/station/ProfitSharing.vue"
          ),
      },
    ],
  },
  {
    path: "/device",
    name: "Device",
    redirect: "/device/list",
    component: () =>
      import(/* webpackChunkName: "Device" */ "../views/device/Device.vue"),
    children: [
      {
        path: "list",
        name: "DeviceList",
        meta: { requireAgentLevel: 2 },
        component: () =>
          import(
            /* webpackChunkName: "DeviceList" */ "../views/device/List.vue"
          ),
      },
      {
        path: "detail",
        name: "DeviceDetail",
        meta: { requireAgentLevel: 2 },
        component: () =>
          import(
            /* webpackChunkName: "DeviceDetail" */ "../views/device/Detail.vue"
          ),
      },
      {
        path: "form",
        name: "DeviceForm",
        meta: { requireAgentLevel: 2 },
        component: () =>
          import(
            /* webpackChunkName: "DeviceForm" */ "../views/device/Form.vue"
          ),
      },
      {
        path: "gun",
        name: "DeviceGun",
        meta: { requireAgentLevel: 2 },
        component: () =>
          import(/* webpackChunkName: "DeviceGun" */ "../views/device/Gun.vue"),
      },
    ],
  },
  {
    path: "/address-picker",
    name: "AddressPicker",
    component: () =>
      import(
        /* webpackChunkName: "AddressPicker" */ "../views/AddressPicker.vue"
      ),
  },
  {
    path: "/union-login",
    name: "UnionLogin",
    meta: { notRequireAuth: true },
    component: () =>
      import(/* webpackChunkName: "UnionLogin" */ "../views/UnionLogin.vue"),
  },
  {
    path: "/forbidden",
    name: "Forbidden",
    meta: { notRequireAuth: true },
    component: () =>
      import(/* webpackChunkName: "Forbidden" */ "../views/Forbidden.vue"),
  },
  {
    path: "/agreement",
    name: "Agreement",
    meta: { notRequireAuth: true },
    component: () =>
      import(/* webpackChunkName: "Agreement" */ "../views/Agreement.vue"),
  },
  {
    // @link https://stackoverflow.com/questions/63526486/vue-router-catch-all-wildcard-not-working
    // path: '*', // 直接这样写会有一个报错
    path: "/:catchAll(.*)",
    name: "NotFound",
    meta: { notRequireAuth: true },
    component: () =>
      import(/* webpackChunkName: "NotFound" */ "../views/NotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// 设置页头标题等
setDocumentTitle(domTitle);

// 路由保镖, 受保护页面需鉴权后才能访问
router.beforeEach((to, from, next) => {
  // 这部分判断登录和代理的代码必须在循环内部
  // 注: window.localStorage 操作是同步的
  const currentUser = store.state.auth.currentUser
    ? store.state.auth.currentUser
    : JSON.parse(window.localStorage.getItem("currentUser"));
  const currentAgent = store.state.auth.agentInfo
    ? store.state.auth.agentInfo
    : JSON.parse(window.localStorage.getItem("agentInfo"));
  const isLogin = isObject(currentUser) && Object.keys(currentUser).length > 0;

  // 已登录的情况下, 不允许进入登录页面
  if (isLogin && to.name == "Login") {
    next({ name: "Home" });
    return;
  }

  if (
    isLogin &&
    currentUser.is_agent &&
    !currentAgent &&
    to.name != "Forbidden"
  ) {
    next({
      name: "Forbidden",
      query: { msg: "agent not matched" },
    });
    return;
  }

  // if (to.meta.notRequireAuth) { // 单路由判断
  // 通过 to.matched.some() 来判断所有路由, 包含其子路由
  // 约定: 未定义 notRequireAuth 或 notRequireAuth = true 则表示无需授权, 否则需登录后才可访问
  if (to.matched.some((record) => !record.meta.notRequireAuth)) {
    if (isLogin) {
      // requireAgentLevel 含义: 0 必须是代理或分润用户才能访问, 1 一级代理可访问, 2 二级代理可访问, 未设置表示普通用户可访问
      const r = [...to.matched].pop(); // 最后一个匹配的路由是当前访问的页面
      if (
        r.meta.requireAgentLevel == 0 &&
        !currentAgent &&
        !currentUser.is_profit
      ) {
        next({
          name: "Forbidden",
          query: {
            ref: encodeURIComponent(to.fullPath),
          },
        });
        return;
      }
      if (
        r.meta.requireAgentLevel > 0 &&
        (!currentAgent || r.meta.requireAgentLevel != currentAgent.level)
      ) {
        next({
          name: "Forbidden",
          query: {
            ref: encodeURIComponent(to.fullPath),
          },
        });
        return;
      }
      next();
    } else if (to.name != "Login") {
      next({
        name: "Login",
        query: {
          redirect: encodeURIComponent(to.fullPath),
        },
      });
    }
    return;
  }

  next();
});

export default router;
